import { get } from "../../Components/firebase/api/db";
import secToTime from "../../Components/Functions/converters/secondsToHourMin";
import dayjs from "../../Components/Functions/dayjs";
import store from "../../Components/Store";
import {
  factoryProductionAtom,
  machinesAtom,
  offlineTimeAtom,
  offtimeBasedEfficiencyAtom,
} from "../../Components/Store/atoms";
import {
  fetchReturnProps,
  departmentsType,
  MachineType,
  MachineStatusType,
} from "./types";
import minifiedSecFormatter from "../../Components/Functions/formatters/minifiedSecFormatter";

interface selectedFactoryType {
  name: string;
  value: number;
  only: boolean;
}

const fetch = (
  date: string | undefined = dayjs().format("YYYY-MM-DD"),
  shifts: {
    shifts: 1 | 2 | 3;
    A: number;
    B: number;
    C: number;
  },
  factories: any[],
  selectedFactory: selectedFactoryType,
): Promise<fetchReturnProps> => {
  return new Promise((resolve, reject) => {
    const mySelectedFactory: any = factories[selectedFactory.value];
    try {
      const fetch = async () => {
        const machinesSnap = await get(`machines`);
        const machinesAllowed = store.get(machinesAtom);
        const offlineTime = store.get(offlineTimeAtom);
        const productionType = store.get(factoryProductionAtom);
        const machines = Object.fromEntries(
          Object.entries(machinesSnap.val() || {}).sort(
            (a: any, b: any) => a[1].sort - b[1].sort,
          ),
        );
        const machinesKeys = Object.keys(machines).filter(
          (key) =>
            (mySelectedFactory === undefined ||
              mySelectedFactory?.machines?.includes(key)) &&
            (!machinesAllowed || machinesAllowed[key]),
        );
        const myMachines = {} as fetchReturnProps["machines"];
        const promises = [];
        promises.push(get(`departments`));
        promises.push(get(`units`));
        promises.push(get(`reports/factory/daily/${date}/unit_power`));
        for await (const machineKey of machinesKeys) {
          promises.push(
            get(`reports/machines/${machineKey}/daily/${date}/total`),
          );
        }
        const [
          departmentsSnap,
          unitsSnap,
          powerOffsSnap,
          ...todayMachinesSnap
        ] = await Promise.all(promises);
        const departments: departmentsType =
          departmentsSnap.val() || ({} as departmentsType);
        const units = unitsSnap.val() || {};
        const powerOffs = (powerOffsSnap.val() || {}) as any;
        let index = 0;
        for await (const machineKey of machinesKeys) {
          const machine = machines[machineKey] as any;
          machine.unit ||= 1;
          if (units[machine.unit] === undefined) continue;
          const {
            cleanDisconnect,
            last_seen,
            lastContact,
            uploadedTil,
          }: {
            connected: boolean;
            cleanDisconnect: boolean;
            last_seen: number;
            lastContact: number;
            uploadedTil: string | undefined;
          } = units[machine.unit] as any;
          const diff = dayjs().diff(dayjs.unix(lastContact || 0), "minute");
          const connected = diff <= offlineTime;
          const todayMachineSnap = todayMachinesSnap[index];
          if (!todayMachineSnap) continue;
          index++;
          const isUpToDate = (() => {
            if (uploadedTil === undefined) return true;
            const uploadedTilDate = dayjs(uploadedTil);
            const now = dayjs();
            // how far behind the data is
            const diff = now.diff(uploadedTilDate, "second");
            return minifiedSecFormatter(diff) + " behind";
          })();
          const todayMachine = (todayMachineSnap.val() || {}) as any;
          const rn = dayjs();
          const rnSecs = rn.hour() * 3600 + rn.minute() * 60 + rn.second();
          const operator = (() => {
            let operator = "A - " + machine.operator_a;
            if (isNaN(shifts.B)) operator = "A - " + machine.operator_a;
            else if (rnSecs > shifts.A && rnSecs < shifts.B)
              operator = "A - " + machine.operator_a;
            else if (isNaN(shifts.C)) operator = "B - " + machine.operator_b;
            else if (rnSecs > shifts.B && rnSecs < shifts.C)
              operator = "B - " + machine.operator_b;
            else if (rnSecs > shifts.C && rnSecs < shifts.A)
              operator = "C - " + machine.operator_c;
            return operator;
          })();
          const shiftTiming = (() => {
            const shift = operator.split(" - ")[0];
            if (shift === "A") {
              if (isNaN(shifts.B))
                return secToTime(shifts.A) + " - " + secToTime(shifts.A);
              else return secToTime(shifts.A) + " - " + secToTime(shifts.B);
            } else if (shift === "B") {
              if (isNaN(shifts.C))
                return secToTime(shifts.B) + " - " + secToTime(shifts.A);
              else return secToTime(shifts.B) + " - " + secToTime(shifts.C);
            } else if (shift === "C")
              return secToTime(shifts.C) + " - " + secToTime(shifts.A);
            else return "NA";
          })();
          const status = (() => {
            if (connected) {
              if (machine.machine_status) return "ON";
              else return "IDLE";
            } else {
              if (cleanDisconnect) return "OFF";
              else return "NA";
            }
          })() as MachineStatusType;
          machine.at = status === "NA" ? lastContact || last_seen : machine.at;
          const statusSince = (() => {
            if (isNaN(machine.at)) return dayjs(machine.at).unix();
            else return +machine.at;
          })();
          const updated = (() => {
            if (isNaN(machine.updated)) return dayjs(machine.updated).unix();
            else return +machine.updated;
          })();
          const offtimeBasedEfficiency =
            store.get(offtimeBasedEfficiencyAtom) || false;
          const off = powerOffs[machine.unit]?.total || 0;
          const machineObj: MachineType = {
            id: machineKey,
            name: machine.machine_model,
            status,
            statusSince,
            shots: todayMachine.shots || 0,
            production_meters: todayMachine.production_meters || 0,
            production: todayMachine.production || 0,
            material: todayMachine.material_usage || 0,
            electricity: todayMachine.electricity_usage || 0,
            idle: todayMachine.offtime || 0,
            off,
            on: todayMachine.ontime || 0,
            cycleTime: +(
              productionType === "meter"
                ? todayMachine.ontime && todayMachine.production_meters
                  ? todayMachine.ontime / todayMachine.production_meters
                  : 0
                : todayMachine.ontime && todayMachine.shots
                ? todayMachine.ontime / todayMachine.shots
                : 0
            ).toFixed(0),
            operator,
            shiftTiming,
            mold: machine.installedMold
              ? machine.installedMold.name
              : machine.mold_name,
            moldSince: machine.mold_start_time,
            unit: machine.unit || 1,
            efficiency: offtimeBasedEfficiency
              ? +(
                  // ratio between ontime and ontime + idle time
                  (
                    todayMachine.ontime &&
                    todayMachine.ontime + todayMachine.offtime + off
                      ? (todayMachine.ontime /
                          (todayMachine.ontime + todayMachine.offtime + off)) *
                        100
                      : 0
                  ).toFixed(2)
                )
              : +(
                  // ratio between ontime and ontime + idle time
                  (
                    todayMachine.ontime &&
                    todayMachine.ontime + todayMachine.offtime
                      ? (todayMachine.ontime /
                          (todayMachine.ontime + todayMachine.offtime)) *
                        100
                      : 0
                  ).toFixed(2)
                ),
            updated,
            isUpToDate,
          };
          if (productionType === "meter") {
            const production_meters_speed: {
              current: number;
              previous: number;
            } = machine.production_meters_speed || {
              current: 0,
              target: 0,
            };
            const rotary_rpm: {
              current: number;
              previous: number;
            } = machine.rotary_rpm || {
              current: 0,
              target: 0,
            };
            machineObj.rotaryRPM = {
              speed: +rotary_rpm.current.toFixed(2),
              change: +(
                ((rotary_rpm.current - rotary_rpm.previous) /
                  rotary_rpm.previous) *
                100
              ).toFixed(0),
            };
            machineObj.currentProductionSpeed = {
              speed: +production_meters_speed.current.toFixed(0),
              change: +(
                ((production_meters_speed.current -
                  production_meters_speed.previous) /
                  production_meters_speed.previous) *
                100
              ).toFixed(0),
            };
          }
          myMachines[machineKey] = machineObj;
        }
        return {
          departments,
          machines: myMachines,
        };
      };
      resolve(fetch());
    } catch (error) {
      reject(error);
    }
  });
};

export default fetch;
