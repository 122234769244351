import { Tooltip, TooltipProps } from "@chakra-ui/react"

interface Props extends TooltipProps {
  children: JSX.Element | JSX.Element[] | string | number
}

const MyTooltip = ({
  children,
  ...props
}: Props) => {
  return <Tooltip
    hasArrow={true}
    placement={"top"}
    bg={"gray.700"}
    color={"white"}
    fontSize={"xs"}
    fontWeight={400}
    borderRadius={"md"}
    boxShadow={"md"}
    p={2}
    m={0}
    {...props}>
    {children}
  </Tooltip>
}

export default MyTooltip